import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class EmitirNFeService {
  static instance = null;
  static getInstance() {
    if (!EmitirNFeService.instance) {
      EmitirNFeService.instance = new EmitirNFeService();
    }
    return EmitirNFeService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (ecfCod, veDoCod, veDoCodIdeMov) => {
    try {
      const response = await this.urlLinxCore.post('/DocumentoFiscal/EmitirNF', { ecfCod, veDoCod, veDoCodIdeMov });
      const status = response.data.itens || [];
      return status;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
