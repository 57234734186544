import { AxiosErrorService } from './../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';
export class OsSubmitService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(body) {
    try {
      // body.tipoFreteId = parseInt(body.tipoFreteId, 10);
      const response = await this.urlLinxCore.post(
        '/ConComodato/InserirOuEditar',
        body,
      );
      return response.data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  }
}
