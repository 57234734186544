import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiRecuperarEmpresaService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(useCache = false) {
    try {
      let cacheKey = null;
      if (useCache) {
        const dadosLogin = getDadosLogin();
        cacheKey = `api_recuperar_empresa_atual_${dadosLogin.cnpj}`; // Chave do cache para este serviço
        const cachedData = memoryCache.get(cacheKey);

        if (cachedData) {
          return cachedData;
        }
      }

      const response = await this.urlLinxCore.get(`/Empresa/Recuperar`);
      var data = response.data.data;
      if (useCache) {
        memoryCache.put(cacheKey, data, 1000 * 60 * 5);
      }
      return data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
