import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiConfereTurnoPedidoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  /**
   * Conferir um turno de pedidos
   * @param {{
   *   turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   * }[]} valores Os valores a serem salvos para o turno de pedidos.
   * @returns {Promise<string>} Uma promessa que resolve para os dados do turno conferido.
   */
  async execute(valores) {
    try {
      const response = await this.urlLinxCore.post(`/TurnoPedido/ConfereTurnoPedido`, valores);
      var r = response.data;
      var data = r;
      return data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
