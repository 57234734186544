import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiRetornarPedidoLocacao {
  static instance = null;
  static getInstance() {
    if (!ApiRetornarPedidoLocacao.instance) {
      ApiRetornarPedidoLocacao.instance = new ApiRetornarPedidoLocacao();
    }
    return ApiRetornarPedidoLocacao.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      const response = await this.urlLinxCore.post(`/ConComodato/retornar-locacao`, body);
      return response.data.data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
