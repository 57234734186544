import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiRemoverItemService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      const response = await this.urlLinxCore.delete(`/ConComodato/RemoverItem/${body.id}/${body.pedidoId}/${body.itemId}`);
      return response.data.data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
