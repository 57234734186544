import { TIPO_SITE } from './TipoSiteConstant';

export const menus = {
  COD_60400: '/dashboard/payhub',
  COD_60410: '/dashboard/gerencial',
  COD_60420: '/fiscal/cst-cfop',
  COD_60430: '/fiscal/dados-adicionais-nf',
  COD_60440: '/cadastro/estrutura-mercadologica',
  COD_60450: '/cadastro/formas-pagamento',
  COD_60460: '/cadastro/funcionarios',
  COD_60470: '/cadastro/grupo-pessoas',
  COD_60480: '/cadastro/lista-preco',
  COD_60490: '/cadastro/maquinas-perifericos',
  COD_60500: '/cadastro/pessoas',
  COD_60510: '/cadastro/produtos',
  COD_60520: '/cadastro/regra-troco',
  COD_60530: '/cadastro/tanques-bombas-bicos',
  COD_60540: '/fiscal/tributacao',
  COD_60550: '/configuracoes/centro-monetarios',
  COD_60560: '/configuracoes/cfop-pis-cofins',
  COD_60570: '/configuracoes/cfop-operacao',
  COD_60580: '/configuracoes/configuracoes-gerais',
  COD_60590: '/configuracoes/contas',
  COD_60600: '/configuracoes/empresa',
  COD_60610: '/configuracoes/estrutura-cartoes',
  COD_60620: '/configuracoes/perfil-acesso',
  COD_60630: '/estoque/ajuste-estoque',
  COD_60640: '/estoque/depositos',
  COD_60650: '/estoque/inventario',
  COD_60660: '/estoque/medicao-tanque',
  COD_60670: '/relatorios/estoque',
  COD_60680: '/financeiro/conferencia-caixa',
  COD_60690: '/financeiro/gerenciamento-financeiro',
  COD_60700: '/relatorios/financeiro/consulta-vendas',
  COD_60710: '/relatorios/financeiro',
  COD_60720: '/fiscal/impostos',
  COD_60730: '/fiscal/inutilizacao-nfe',
  COD_60740: '/fiscal/lmc',
  COD_60750: '/fiscal/nota-fiscal',
  COD_60760: '/fiscal/sped-contribuicoes',
  COD_60770: '/fiscal/sped-fiscal',
  COD_60940: '/financeiro/fluxo-caixa',
  COD_60950: '/fiscal/manifestacao-destinatario',
  COD_60960: '/fiscal/monitoramento-nfe',
  COD_60980: '/financeiro/faturamento-vendas-prazo',
  COD_60990: '/configuracoes/carga-inicial',
  COD_61000: '/configuracoes/carga-inicial/produtos',
  COD_61010: '/configuracoes/carga-inicial/pessoas',
  COD_61020: '/fiscal/importacao-ibpt',
  COD_80520: '/relatorios/vendas',
  COD_80530: '/relatorios/gerenciamento-financeiro',
  COD_80540: '/configuracoes/pos-mfe',
  COD_80550: '/relatorios/margem-markup',
  COD_80555: '/relatorios/notas-fiscais',
  COD_80560: '/relatorios/movimentacao-estoque',
  COD_80565: '/relatorios/fechamento-caixa',
  COD_80570: '/relatorios/movimentacao-financeira-estoque',
  COD_80580: '/relatorios/resultado-operacional',
  COD_80590: '/relatorios/curva-abc',
  COD_80595: '/relatorios/compras-documento-fiscal',
  COD_80610: '/exportacoes/fortes-fiscal',
  COD_60772: '/fiscal/apuracao-icms-st',
  COD_60774: '/fiscal/apuracao-icms',
  COD_80620: '/cadastro/requisicao-LGPD',
  COD_5217: '/financeiro/contas-a-receber/gerenciamento-de-arquivos/remessa',
  COD_5218: '/financeiro/contas-a-receber/gerenciamento-de-arquivos/retorno',
  COD_7250: '/informacoes-fiscais/sped-fiscal/geracao-arquivo',
  COD_7310: '/informacoes-fiscais/sped-pis/cofins/geracao',
  COD_13103: '/cadastros/sistema/configuracoes-gerais',
  COD_13120: '/cadastros/sistema/feriados-locais',
  COD_13125: '/cadastros/sistema/feriados-nacionais',
  COD_13105: '/cadastros/sistema/funcionarios',
  COD_13110: '/cadastros/sistema/perfis-de-acesso',
  COD_13101: '/cadastros/sistema/versao',
  COD_13115: '/cadastros/sistema/visoes-de-estabelecimentos',
  COD_13130: '/cadastros/sistema/config-sistema',
  COD_13210: '/cadastros/estabelecimento/almoxarifados',
  COD_13235: '/cadastros/estabelecimento/clientes',
  COD_13290: '/cadastros/estabelecimento/agrupadores-de-clientes',
  COD_13215: '/cadastros/estabelecimento/estabelecimento',
  COD_13245: '/cadastros/estabelecimento/fabricantes',
  COD_13275: '/cadastros/estabelecimento/faixas-de-comissionamento',
  COD_13240: '/cadastros/estabelecimento/fornecedores',
  COD_13225: '/cadastros/estabelecimento/justificativas',
  COD_13205: '/cadastros/estabelecimento/maquinas',
  COD_13280: '/cadastros/estabelecimento/perifericos',
  COD_13250: '/cadastros/estabelecimento/transportadoras',
  COD_13220: '/cadastros/estabelecimento/turnos',
  COD_13230: '/cadastros/estabelecimento/comandas/cartoes-de-comand',
  COD_13232: '/cadastros/estabelecimento/comandas/lote-de-cartoe',
  COD_13231: '/cadastros/estabelecimento/regras-de-impressao',
  COD_13330: '/financeiro/cadastros/bancos',
  COD_13325: '/financeiro/cadastros/centros-monetarios',
  COD_13305: '/cadastros/financeiro/contas',
  COD_13315: '/cadastros/financeiro/formas-de-pagamento',
  COD_13317: '/cadastros/financeiro/troco-e-devolucao',
  COD_13420: '/cadastros/produtos/estrutura-mercadologica',
  COD_13440: '/cadastros/produtos/listas-de-precos',
  COD_13435: '/cadastros/produtos/montagem-de-kit/composto',
  COD_13107: '/cadastros/produtos/ncm',
  COD_13405: '/cadastros/produtos/produtos',
  COD_13480: '/cadastros/produtos/carga-produtos',
  COD_13460: '/cadastros/produtos' + (TIPO_SITE === 'RetaguardaEmporioPro' ? '/gestao-de-pendencias' : '/pendencias-cadastro-padrao'),
  COD_13430: '/cadastros/produtos/agrupadores-de-produtos/para-consulta',
  COD_13515: '/cadastros/fiscal/aidf',
  COD_13530: '/cadastros/fiscal/cfops-por-operacao',
  COD_13533: '/cadastros/fiscal/configuracao-cfop-de-pis-cofins',
  COD_13520: '/cadastros/fiscal/cst-x-cfop',
  COD_13570: '/cadastros/fiscal/regras-fiscais',
  COD_13510: '/cadastros/fiscal/dados-adicionais-para-notas-fiscais',
  COD_13560: '/cadastros/fiscal/impostos/configuracao-pis_cofin',
  COD_13555: '/cadastros/fiscal/impostos/grupo-de-imposto',
  COD_13545: '/cadastros/fiscal/impostos/imposto',
  COD_13550: '/cadastros/fiscal/impostos/observacoes-de-imposto',
  COD_16170: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/apuracao-de-impostos',
  COD_16160: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/atendimentos',
  COD_16150: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/comissao',
  COD_16130: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/curva-abc',
  COD_16133: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/curva-abc-comparativo',
  COD_16140: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/diarias',
  COD_16110: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/evolucao',
  COD_16135: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/kits-vendidos',
  COD_16125: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/por-alíquota',
  COD_16155: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/por-cliente/placa',
  COD_16120: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/por-operador',
  COD_16105: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/realizadas',
  COD_16115: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/resumo-setor-dia-hora',
  COD_16185: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/documentos-fiscais-por-faixa-de-valor',
  COD_16190: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/consulta-vendas',
  COD_16195: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/vendas/promocao-dinamico',
  COD_16210: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/fiscal/mapa-resumo-do-ecf',
  COD_16215: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/fiscal/livros-fiscais',
  COD_16310: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/alteracao-do-preco-de-venda',
  COD_16325: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/alteracao-do-preco-de-compra',
  COD_16320: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/compras-por-documento-fiscal',
  COD_16365: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/documentos-dinamico',
  COD_16405: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/custos',
  COD_16315: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/vendas-por-documento-fiscal',
  COD_16335: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/auditoria-de-operacoes',
  COD_16355: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/comprasdinamico',
  COD_16360: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/controle-de-ativos',
  COD_16415: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/formas-de-pagamento',
  COD_16410: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/margem-e-mark-up',
  COD_16420: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/movimentacao-financeira-do-estoque',
  COD_16425: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/resultado-operacional',
  COD_16440: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/lista-de-debito',
  COD_16450: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/ponto-equilibrio',
  COD_16505: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/tesouraria/alteracoes-de-formas-de-pagamento',
  COD_16510: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/tesouraria/movimentacao-do-turno',
  COD_16515: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/tesouraria/sangrias/suprimentos',
  COD_16520: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/tesouraria/movimentacao-turno-pedido-dinamico',
  COD_16625: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/giro',
  COD_16620: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/inventario',
  COD_16615: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/itens-nao-vendidos-desde...',
  COD_16640: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/movimentacao-gerencial',
  COD_16605: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/posicao',
  COD_16630: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/prazo-medio',
  COD_16635: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/estoque/validade-de-produtos',
  COD_16805: TIPO_SITE === 'RetaguardaEmporioPro' ? '' : '/consultas/pista/mapa-de-pista',
  COD_16810: TIPO_SITE === 'RetaguardaEmporioPro' ? '' : '/consultas/pista/vendas-por-frentista',
  COD_16905: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/comandas/produtividade-de-atendente',
  COD_16950: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/pedidos-dinamico',
  COD_17105: '/operacoes/fiscal/entrada-movimento-reducao-z',
  COD_17400: '/operacoes/fiscal/abertura/fechamento-mes',
  COD_17130: '/operacoes/fiscal/notas-fiscais',
  COD_17350: '/operacoes/estoque/contagem',
  COD_17255: '/operacoes/estoque/movimentacao/ajuste-de-estoqu',
  COD_17260: '/operacoes/estoque/movimentacao/transferencia-entre-almoxarifado',
  COD_17510: '/operacoes/tesouraria/alteracao-de-forma-de-pagamento',
  COD_17513: '/operacoes/tesouraria/caderno-de-pagamento',
  COD_17515: '/operacoes/tesouraria/conferencia-de-turno',
  COD_17610: '/operacoes/produtos/etiquetas',
  COD_17620: '/operacoes/produtos/montagem-de-lista-de-precos',
  COD_17640: '/operacoes/produtos/programacao-de-precos',
  COD_17650: '/operacoes/produtos/reajuste-de-precos',
  COD_17660: '/operacoes/produtos/ajuste-em-lote',
  COD_17710: '/operacoes/ferramentas/exportacao-de-arquivos',
  COD_17715: '/operacoes/ferramentas/importacao-tabela-ibpt',
  COD_5120: '/financeiro/contas-a-pagar/aceite',
  COD_5115: '/financeiro/contas-a-pagar/altera-lote',
  COD_5110: '/financeiro/contas-a-pagar/gerenciamento-titulos',
  COD_5160: '/financeiro/contas-a-pagar/consultas/diario-auxiliar',
  COD_5135: '/financeiro/contas-a-pagar/consultas/extrato',
  COD_5140: '/financeiro/contas-a-pagar/consultas/pagamentos',
  COD_5150: '/financeiro/contas-a-pagar/consultas/titulos-em-aberto',
  COD_5215: '/financeiro/contas-a-receber/altera-lote',
  COD_5210: '/financeiro/contas-a-receber/gerenciamento-titulos',
  COD_5257: '/financeiro/contas-a-receber/consultas/aging-list',
  COD_5258: '/financeiro/contas-a-receber/consultas/composicao-da-carteira',
  COD_5260: '/financeiro/contas-a-receber/consultas/diario-auxiliar',
  COD_5235: '/financeiro/contas-a-receber/consultas/extrato',
  COD_5240: '/financeiro/contas-a-receber/consultas/recebimentos',
  COD_5250: '/financeiro/contas-a-receber/consultas/titulos-em-aberto',
  COD_5535: '/financeiro/tesouraria/fechamento-caixa',
  COD_5340: '/financeiro/tesouraria/lancamentos',
  COD_5314: '/financeiro/tesouraria/pagamentos/liquidacao-parcial',
  COD_5312: '/financeiro/tesouraria/pagamentos/liquidacao',
  COD_5324: '/financeiro/tesouraria/recebimentos/liquidacao-parcial',
  COD_5322: '/financeiro/tesouraria/recebimentos/liquidacao',
  COD_5335: '/financeiro/tesouraria/depositos/cartoes-bancos',
  COD_5333: '/financeiro/tesouraria/depositos/dinheiro-bancos',
  COD_5334: '/financeiro/tesouraria/transferencias/bancos',
  COD_5354: '/financeiro/tesouraria/consulta/diario-auxiliar',
  COD_5351: '/financeiro/tesouraria/consulta/extrato',
  COD_5356: '/financeiro/tesouraria/consulta/extrato-conciliado',
  COD_5357: '/financeiro/tesouraria/consulta/lancamentos',
  COD_5352: '/financeiro/tesouraria/consulta/movimentacao',
  COD_5362: '/financeiro/tesouraria/consulta/depositos',
  COD_5358: '/financeiro/tesouraria/depositos/consultas',
  COD_5430: '/financeiro/fluxo-de-caixa/cash-flow',
  COD_5420: '/financeiro/fluxo-de-caixa/consulta-saldos',
  COD_5411: '/financeiro/fluxo-de-caixa/gerenciamento-financeiro',
  COD_5434: '/financeiro/fluxo-de-caixa/planejamento-financeiro',
  COD_5530: '/financeiro/conciliacao/bancos',
  COD_5590: '/financeiro/conciliacao/cartoes',
  COD_5544: '/financeiro/conciliacao/consultas/diferencas',
  COD_5542: '/financeiro/conciliacao/consultas/extrato',
  COD_5561: '/financeiro/conciliacao/bordero/cheques',
  COD_5582: '/financeiro/tesouraria/bordero/cheques',
  COD_5700: '/financeiro/conta-digital-integrada',
  COD_5710: '/financeiro/conta-digital-integrada/extrato',
  COD_7110: '/informacoes-fiscais/sped-contabil/contabilizacao-de-impostos',
  COD_7120: '/informacoes-fiscais/sped-contabil/geracao-de-arquivo',
  COD_7290: '/informacoes-fiscais/sped-fiscal/consulta-fila',
  COD_7280: '/informacoes-fiscais/sped-fiscal/download-arquivos-recebidos',
  COD_7228: '/informacoes-fiscais/sped-fiscal/nota-fiscal/analitico-nf-energia-eletrica',
  COD_7229: '/informacoes-fiscais/sped-fiscal/nota-fiscal/analitico-nf-servicos-de-comunicacao',
  COD_7222: '/informacoes-fiscais/sped-fiscal/nota-fiscal/obs-lancamento-fiscal',
  COD_7223: '/informacoes-fiscais/sped-fiscal/nota-fiscal/obs-lancamento-fiscal-transporte',
  COD_7224: '/informacoes-fiscais/sped-fiscal/nota-fiscal/registro-analitico-do-resumo-diario-nfs',
  COD_7226: '/informacoes-fiscais/sped-fiscal/nota-fiscal/registro-analitico-dos-documentos',
  COD_7232: '/informacoes-fiscais/sped-fiscal/equipamento-ecf',
  COD_7244: '/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-icms-st',
  COD_7242: '/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-icms',
  COD_7246: '/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-ipi',
  COD_7320: '/informacoes-fiscais/sped-pis/cofins/',
  COD_10210: '/nf-e/manifestacao/',
  COD_10200: '/nf-e/monitoramento/',
  COD_13260: '/cadastros/estabelecimento/cargos',
  COD_13243: '/cadastros/estabelecimento/intervencoes-tecnicas',
  COD_13255: '/cadastros/estabelecimento/tanques-bombas-bicos',
  COD_13355: '/parametros-cartoes/parametros-do-estabelecimento/',
  COD_13360: '/parametros-cartoes/redes-autorizadoras/',
  COD_13385: '/cadastros/financeiro/estrutura-de-cartoes/bandeira',
  COD_13375: '/cadastros/financeiro/estrutura-de-cartoes/estrutura-cartoe',
  COD_13390: '/cadastros/financeiro/estrutura-de-cartoes/produto',
  COD_13380: '/cadastros/financeiro/estrutura-de-cartoes/rede',
  COD_13422: '/cadastros/produtos/codigos-anp',
  COD_13470: '/cadastros/produtos/delivery',
  COD_13490: '/cadastros/produtos/observacoes',
  COD_13445: '/produtos/cardapio-digital/cadastros/cardapi',
  COD_13446: '/produtos/cadastros/cardapio-digital',
  COD_15310: '/cadastros/produtos/ingredientes/grupo',
  COD_15320: '/cadastros/produtos/ingredientes/ingrediente',
  COD_16165: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/vendas/promocoes-br' : '',
  COD_16175: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/vendas/vendas-br' : '',
  COD_16196: '/consultas/vendas/vendas-premmia',

  //Conflito com outro código de menu
  //COD_16195: '/consultas/vendas/relatorio-diario-situacao',

  COD_16340: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/alteracao-de-ingredientes',
  COD_16345: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/auditoria/custos-de-ingredientes',
  COD_16430: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/financeiro/resultado-operacional',

  COD_16920: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/comandas/comandas-digitais',
  COD_16910: '/comandas/consultas/',
  COD_16955: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/br/anexos-e-lair' : '',
  COD_16957: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/br/margem-br' : '',
  COD_16992: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/promocoes/br-mania' : '',
  COD_16991: TIPO_SITE === 'RetaguardaSellerWeb' ? '/consultas/promocoes/premmia' : '',
  COD_16999: (TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas') + '/raio-x',
  COD_17120: '/operacoes/fiscal/inutilizacao-de-sequencia-de-nf-e',
  COD_17110: '/operacoes/fiscal/lmc',
  COD_17300: '/operacoes/estoque/medicao-de-tanques',
  COD_17540: '/operacoes/tesouraria/conferencia-de-turno-de-pedidos',
  COD_17800: '/operacoes/pedidos',
  COD_22100: '/matriz/produtos-ajuste-de-preco',
  COD_22200: '/matriz/estoque-posicao',
  COD_22300: '/matriz/vendas-realizadas',
  COD_22400: '/matriz/vendas-documentos-fiscais-por-faixa-de-valor',
  COD_22500: '/matriz/vendas-curva-abc-comparativo',
  COD_22520: '/matriz/movimentacao-turno-pedido-dinamico',
  COD_22600: '/matriz/vendas-gerais',
  COD_22650: '/matriz/pedidos-dinamico',
  COD_22660: '/matriz/documentos-dinamico',
  COD_22800: '/matriz/promocao-dinamico',
  COD_40000: '/dashboard/horusbi',
};
