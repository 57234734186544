import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class AtualizarClienteService {
  static instance = null;
  urlLinxCore = null;
  static getInstance() {
    if (!AtualizarClienteService.instance) {
      AtualizarClienteService.instance = new AtualizarClienteService();
    }
    return AtualizarClienteService.instance;
  }
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(data) {
    try {
      var body = { id: data.id, nome: data.nome, ie: data.ie };
      await this.urlLinxCore.post(`/Cliente/Atualizar`, body);
      return true;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
