import { configureStore } from '@reduxjs/toolkit';
import filtroOsReducer from './slices/consignadoComodato/filtroOsSlice';
import novaOsItemReducer from './slices/consignadoComodato/novaOsItemSlice';
import novaOsPagamentoReducer from './slices/consignadoComodato/novaOsPagamentoSlice';
import novaOsReducer from './slices/consignadoComodato/novaOsSlice';
import consignadoComodatoSharedDataReducer from './slices/consignadoComodato/SharedData.slice';

export const store = configureStore({
  reducer: {
    novaOs: novaOsReducer,
    novaOsItem: novaOsItemReducer,
    novaOsPagamento: novaOsPagamentoReducer,
    filtroOs: filtroOsReducer,
    consignadoComodatoSharedData: consignadoComodatoSharedDataReducer,
  }
});
