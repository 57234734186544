export class AxiosErrorService {
  execute(er) {
    if (er.isAxiosError) {
      if (er.message === 'Network Error') {
        throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
      }
      let data = er.response.data;
      if (!data) {
        data = {
          message: er.message || 'Erro desconhecido. Tente novamente mais tarde.',
        };
      }
      if (data.constructor.name === 'String') {
        throw new Error(data);
      }
      if (data.message) {
        throw new Error(data.message);
      }
      if (data.errors) {
        let msg = '';
        for (const key in data.errors) {
          if (Object.hasOwnProperty.call(data.errors, key)) {
            const element = data.errors[key];
            msg = `${msg}${msg.length > 0 ? ' - ' : ''}${element}`;
          }
        }
        throw new Error(msg);
      }
      throw new Error((data || {}).message || 'Sem mensagem de erro');
    }
    throw new Error(er.message || 'Sem mensagem de erro');
  }
}
const axiosErrorService = new AxiosErrorService();
export default axiosErrorService;
