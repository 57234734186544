import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiGetParamentroByIdService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(id) {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `api_get_parametro_by_id_${id}_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        // Retorna o resultado em cache se existir
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`ConfiguracoesGerais/RecuperarPorCodigo/${id}`);
        const data = response.data.item;
        memoryCache.put(cacheKey, data, 30 * 60 * 1000);
        return data;
      }
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
