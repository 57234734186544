import { AxiosErrorService } from  '../../../../../services/core/axiosError.service'
import { UrlLinxCoreService } from '../../../../../services/core/urlLinxCore.service';
export class FaturarPedidoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(pedidoId) {
    try {

      const envioNps =  sessionStorage.getItem('envioNps') == 'true' ? true : false;

      const resp = await this.urlLinxCore.post('/ConComodato/faturar-pedido', {pedidoId: pedidoId, EnviarNps: envioNps});
      const data = resp.data;

      const estaFaturado = data ||false;
      return estaFaturado;

    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  }
}
