import memoryCache from 'memory-cache';
import _ from 'lodash';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiListarFormasPagamentoService {
  static instance = null;
  static getInstance() {
    if (!ApiListarFormasPagamentoService.instance) {
      ApiListarFormasPagamentoService.instance = new ApiListarFormasPagamentoService();
    }
    return ApiListarFormasPagamentoService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (useCache = false) => {
    let cacheKey = '';
    if (useCache) {
      const dadosLogin = getDadosLogin();
      cacheKey = `ApiListarFormasPagamentoService${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
    }
    try {
      const response = await this.urlLinxCore.get('/FormaPagamento/listar');
      const pagamentos = response.data.data || [];
      const pagamentosAtivos = pagamentos
        .filter(x => !x.inativo)
        .map(x => {
          x.id = x.id.toString();
          return x;
        });
      const data = _.sortBy(pagamentosAtivos, 'id', 'asc');
      if (useCache) {
        memoryCache.put(cacheKey, data, 30 * 60 * 1000); // cache de 30 minutos
      }
      return data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
