import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiListarOsService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (filter = null, listarItens = false) => {
    if (!filter) {
      filter = {};
    }
    const query = { ...filter };

    let u = new URLSearchParams(query).toString();
    u += `&listarItens=${listarItens ? true : false}`;

    try {
      let resp = null;
      if (listarItens) resp = await this.urlLinxCore.get(`/ConComodato/ListarItens?${u}`);
      else resp = await this.urlLinxCore.get(`/ConComodato/listar?${u}`);
      return resp.data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
