import { getCentrosMonetariosBoleto } from '../../../../services/CentrosMonetarios.service';
import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiListarCentrosMonetariosService {
  static instance = null;
  static getInstance() {
    if (!ApiListarCentrosMonetariosService.instance) {
      ApiListarCentrosMonetariosService.instance = new ApiListarCentrosMonetariosService();
    }
    return ApiListarCentrosMonetariosService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async () => {
    try {
      var result = await getCentrosMonetariosBoleto();

      const centroMonetarios = result.data || [];
      let listaCentroMonetarios = [];

      if(centroMonetarios.length > 0){
        listaCentroMonetarios = result.data.map(centro => ({
          id: centro.ceMoCod,
          nome: centro.nomeBanco,
          outrasCobrancas: centro.ceMoValOutrasCobrancas
        }));

      }
      return listaCentroMonetarios;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  }
}
