import { CharToInt } from './utils';
export function CheckIEBA(ie) {
  if (ie.length !== 8) return false;
  var die = ie.substring(0, 8);
  var nro = new Array(8);
  var dig = -1;
  var i = 0;
  for (i = 0; i <= 7; i++) {
    nro[i] = CharToInt(die.charAt(i));
  }
  var NumMod = 0;
  if (String(nro[0]).match(/[0123458]/)) NumMod = 10;
  else NumMod = 11;
  var b = 7;
  var soma = 0;
  for (i = 0; i <= 5; i++) {
    soma += nro[i] * b;
    b--;
  }
  i = soma % NumMod;
  if (NumMod === 10) {
    if (i === 0) {
      dig = 0;
    } else {
      dig = NumMod - i;
    }
  } else {
    if (i <= 1) {
      dig = 0;
    } else {
      dig = NumMod - i;
    }
  }
  var resultado = dig === nro[7];
  if (!resultado) {
    return false;
  }
  b = 8;
  soma = 0;
  for (i = 0; i <= 5; i++) {
    soma += nro[i] * b;
    b--;
  }
  soma += nro[7] * 2;
  i = soma % NumMod;
  if (NumMod === 10) {
    if (i === 0) {
      dig = 0;
    } else {
      dig = NumMod - i;
    }
  } else {
    if (i <= 1) {
      dig = 0;
    } else {
      dig = NumMod - i;
    }
  }
  return dig === nro[6];
} //ba
