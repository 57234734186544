import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiReabreTurnoPedidoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(turnoId) {
    try {
      const response = await this.urlLinxCore.post(`/TurnoPedido/ReabreTurnoPedido/${turnoId}`, {});
      var r = response.data;
      var data = r;
      return data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
