import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiRecuperarAcessosPorMenuPaiIdService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(menuPaiId) {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiRecuperarAcessosPorMenuPaiIdService${dadosLogin.cnpj}/${menuPaiId}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`/Funcionario/AcessosMenuPai/${menuPaiId}`);
        var data = response.data.itens;
        memoryCache.put(cacheKey, data, 1000 * 60 * 3);
        return data;
      }
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
