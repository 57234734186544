import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

/**
 * Um serviço para recuperar um turno de pedidos completo usando a API.
 */
export class ApiRetornaTurnoPedidoCompletoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  /**
   * Recupera um turno de pedidos completo usando o ID do turno especificado.
   * @param {string} turnoId O ID do turno a ser recuperado.
   * @param {boolean} useCache Se deve usar dados em cache, se disponíveis.
   * @returns {Promise<{
   *   turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   *   pagamentos: Array<{
   *     turnoId: long,
   *     codigoEstabelecimento: short,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     numeroPedido: long,
   *     valor: decimal,
   *     dataHora: DateTime,
   *     codigoRedeCartao: short,
   *     descricaoRedeCartao: string,
   *     codigoProdutoCartao: short,
   *     descricaoProdutoCartao: string,
   *     codigoBandeiraCartao: short,
   *     descricaoBandeiraCartao: string,
   *     nsuCartao: string,
   *   }>,
   *   sangrias: Array<{
   *     sangriaId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSangrado: decimal,
   *     dataSangria: DateTime,
   *     observacao: string,
   *   }>,
   *   suprimentos: Array<{
   *     suprimentoId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSuprido: decimal,
   *     dataSuprimento: DateTime,
   *     observacao: string,
   *   }>
   * }>} Uma promessa que resolve para os dados do turno de pedidos completo.
   */
  async execute(turnoId, useCache = false) {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiRetornaTurnoPedidoCompletoService${dadosLogin.cnpj}?${turnoId}`; // Chave do cache para este serviço
      const cachedData = useCache ? memoryCache.get(cacheKey) : null;
      if (cachedData) {
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`/TurnoPedido/RecuperarTurnoPedidoCompleto/${turnoId}`);
        var r = response.data;
        var data = r.item;
        if (useCache) memoryCache.put(cacheKey, data, 1000 * 60 * 3);
        return data;
      }
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
