import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiRetornarPedidoComodato {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      const response = await this.urlLinxCore.post(`/ConComodato/retornar-comodato`, body);
      return response.data.data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
