import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiRecuperarSangriasService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(turnoId) {
    try {
      var response = await this.urlLinxCore.get(`/TurnoPedido/RecuperarTurnoPedidoSangria/${turnoId}`);
      var data = response.data;
      return data.itens || [];
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
