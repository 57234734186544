import React, { useCallback, useEffect, useState } from 'react';
import { selectNovaOs, useNovaOs } from './../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { useDispatch, useSelector } from 'react-redux';

import styles from './../components.module.scss';
import { DownloadContratoComodato } from '../../../../../services/Relatorios/DownloadContratoComodato';
import Swal from 'sweetalert2';
import { DownloadReciboComodato } from '../../../../../services/Relatorios/DownloadReciboComodato';

export function HeaderForm() {
  const { values } = useSelector(selectNovaOs);
  const { showImpressaoPedido } = useNovaOs();
  const [faturado, setFaturado] = useState(false);
  const [busyRecibo, setBusyRecibo] = useState(false);
  const [busyContrato, setBusyContrato] = useState(false);
  const dispatch = useDispatch();
  useEffect(
    e => {
      if (!values.pedidos) return;
      if (!values.pedidos.length) return;
      if (!values.pedidos.find(x => x.veDoCod)) return;
      setFaturado(true);
    },
    [values.pedidos],
  );
  const onClickContrato = useCallback(async e => {
    e.preventDefault();
    try {
      setBusyContrato(true);
      const download = new DownloadContratoComodato();
      await download.execute(values.osId, values.numeroOs);
      setBusyContrato(false);
    } catch (e) {
      setBusyContrato(false);
      Swal.fire('Erro', e.message, 'error');
    }
  }, [values.osId, values.numeroOs]);
  const onClickPedido = useCallback(
    e => {
      e.preventDefault();
      dispatch(showImpressaoPedido(true));
    },
    [dispatch, showImpressaoPedido],
  );
  const onClickRecibo = useCallback(
    async e => {
      e.preventDefault();
      try {
        setBusyRecibo(true);
        const download = new DownloadReciboComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyRecibo(false);
      } catch (e) {
        setBusyRecibo(false);
        Swal.fire('Erro', e.message, 'error');
      }
    },
    [values.numeroOs, values.osId],
  );
  return (
    <div className={`d-flex row ${styles.header}`}>
      <div className="col col-12">
        <h2 className="float-left ml-3 mt-3 h-100">
          Pedido Consignado/Comodato <small className="muted">{values.id !== -1 && values.id ? values.numeroOs.toString().padStart(10, '0') : 'NOVO'}</small>
        </h2>
        <button style={{ width: '200px' }} disabled={values.id === -1 || !values.id} type="button" className={`btn btn-link float-right h-100`} onClick={onClickPedido}>
          <i className="dx-icon-print"></i>&nbsp;&nbsp; PEDIDO
        </button>
        <button
          style={{ width: '200px' }}
          disabled={busyRecibo || !faturado || !((values || {}).pedidos || []).find(x => [2, 3, 8].includes(x.tipoPedido.id))}
          type="button"
          className={`btn btn-link float-right h-100`}
          onClick={onClickRecibo}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; {`${busyRecibo ? 'GERANDO...' : 'RECIBO' }`}
        </button>
        <button
          disabled={!faturado || !((values || {}).pedidos || []).find(x => x.tipoPedido.id === 3)}
          style={{ width: '200px' }}
          className={`float-right btn btn-link h-100`}
          onClick={onClickContrato}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; CONTRATO
        </button>
      </div>
    </div>
  );
}
