import axios from "axios";
import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { TOKEN_PASS_AXIOS } from "../../../../shared/constants/Const";
import { getUrl } from "../../../../shared/utils/Utils";
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export class ApiGerarBoletoService {
    urlLinxCore = null;
    constructor() {
      this.urlLinxCore = new UrlLinxCoreService().urlBase();
    }

    async execute(body) {
      try {

        var result = await axios.post(`LxApi/v1/boleto/gerar-boleto`, body);

        return result;
      } catch (error) {
        new AxiosErrorService().execute(error);
      }
    }

    async imprimeBoleto(body) {
      try {

        var result = await axios.post(`LxApi/v1/boleto/imprimir-boleto`, body);

        return result;
      } catch (error) {
        new AxiosErrorService().execute(error);
      }
    }
  }
