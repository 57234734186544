import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from '../../../../services/core/urlLinxCore.service';

export class ApiIntegrarVTexService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async () => {
    try {
      const resp = await this.urlLinxCore.post(`/vtex/integrar`);
      return resp.data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
