import memoryCache from 'memory-cache';
import _ from 'lodash';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiRecuperarBandeirasService {
  static instance = null;
  static getInstance() {
    if (!ApiRecuperarBandeirasService.instance) {
      ApiRecuperarBandeirasService.instance = new ApiRecuperarBandeirasService();
    }
    return ApiRecuperarBandeirasService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (useCache = false) => {
    let cacheKey = '';
    if (useCache) {
      const dadosLogin = getDadosLogin();
      cacheKey = `ApiRecuperarBandeirasService${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
    }
    try {
      const response = await this.urlLinxCore.get('/financeiro/bandeiras');
      let data = response.data.itens || [];
      data = _.sortBy(data, 'descricao', 'asc');
      if (useCache) {
        memoryCache.put(cacheKey, data, 30 * 60 * 1000); // cache de 30 minutos
      }
      return data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
