import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiRecuperarDanfeService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(key) {
    try {
      const response = await this.urlLinxCore.get(`/ConComodato/danfe/${key}`);
      return response.data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
