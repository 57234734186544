import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiAdicionarItemService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      const response = await this.urlLinxCore.post(`/ConComodato/AdicionarItem`, body);
      return (response.data || {}).data;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
