import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiAbrirTurnoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  /**
   * Abrir um turno de pedido
   * @returns {Promise<{
   *  item: {
    *  id number;
    *  Numero: number;
    *  HoraInicial: Date;
    *  HoraFinal: Date;
    *  Inativo: boolean;
    *  Excluido: boolean;
    *  TmpAntecipar: Date;
    *  DataConferencia: Date;
    *  Conferido: boolean;
    *  TurnoOperador: {};
    *  TurnoTrabalho: {};
    *  TurnoMacro: {};
   *  }
   * }>}
   */
  async execute() {
    try {
      console.log('AbrirTurno');
      const response = await this.urlLinxCore.post(`/TurnoPedido/AbrirTurno`, {});
      var r = response.data;
      var data = r;
      return data.item;
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
  }
}
