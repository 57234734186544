import memoryCache from 'memory-cache';
import _ from 'lodash';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiRecuperarRedesService {
  static instance = null;
  static getInstance() {
    if (!ApiRecuperarRedesService.instance) {
      ApiRecuperarRedesService.instance = new ApiRecuperarRedesService();
    }
    return ApiRecuperarRedesService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (useCache = false) => {
    let cacheKey = '';
    if (useCache) {
      const dadosLogin = getDadosLogin();
      cacheKey = `ApiRecuperarRedesService${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
    }
    try {
      const response = await this.urlLinxCore.get('/financeiro/redes');
      let data = response.data.itens || [];
      data = _.sortBy(data, 'descricao', 'asc');
      if (useCache) {
        memoryCache.put(cacheKey, data, 30 * 60 * 1000); // cache de 30 minutos
      }
      return data;
    } catch (er) {
      new AxiosErrorService().execute(er);
    }
  };
}
